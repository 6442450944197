import axios from "axios";

const ENVIRONMENT = "prod";

function getBaseUrl(env) {
  switch (env) {
    case "dev":
      return "https://api.babamarket.dev/v2";
    case "prod":
      return "https://dev.babamarket.co.id/v2/";
    default:
      throw new Error("Invalid environment");
  }
}

function getSignature(env) {
  switch (env) {
    case "dev":
      return "7yYOmqPGc68kDReiZgSANhqOCB0f/soqXtDjIZ/BhWc=";
    case "prod":
      return "A/Lsr4xaxlGS8AFxP0UwZOTm8zoVHzHxz8dsatDDedM=";
    default:
      throw new Error("Invalid environment");
  }
}

const BASE_URL = getBaseUrl(ENVIRONMENT);
const SIGNATURE = getSignature(ENVIRONMENT);

const API = async (
  url,
  options = { method: "GET", body: {}, head: {}, responseType: "json" }
) => {
  const request = {
    baseURL: BASE_URL,
    method: options.method,
    timeout: 20000,
    url,
    headers: {
      ...options.head,
      "Accept": "application/json",
      "X-SIGNATURE": SIGNATURE,
    },
    responseType: options.responseType,
    data: options.body,
  };

  try {
    const res = await axios(request);
    return res;
  } catch (error) {
    if (error.response && error.response.status === 401) {
    }
    throw error;
  }
};

const apiProvider = {
  // Auth
  Login: async (params) => {
    return API("auth/login", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  Refresh: async (params) => {
    return API("auth/refresh", {
      method: "POST",
      head: {
        "Content-Type": "application/json",
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  Logout: async (token, params) => {
    return API("auth/logout", {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Auth

  // Home
  GetHome: async (token, range) => {
    return API(`panel/home?range=${range}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductHome: async (token, range) => {
    return API(`panel/home/product`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Home

  // Account
  GetProfile: async (token) => {
    return API(`panel/profile`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProfile: async (params, token) => {
    return API(`panel/profile/update`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePassword: async (params, token) => {
    return API(`panel/password/update`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Account

  // Region
  GetRegion: async (token, params) => {
    return API(`panel/region/${params}`, {
      method: "GET",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Region

  // User
  GetUser: async (token, query, role, page, per_page) => {
    return API(
      `panel/user?keyword=${query}&role=${role}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetTransactionUser: async (token, id) => {
    return API(`panel/user/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  AddUser: async (params, token) => {
    return API(`panel/user/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ExportUser: async (role, token) => {
    return API(`panel/user/export?role=${role}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
  },
  UpdateStatusUser: async (token, id) => {
    return API(`panel/user/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateUser: async (params, token, id) => {
    return API(`panel/user/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteUser: async (token, id) => {
    return API(`panel/user/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End User

  // Expedition
  GetPriceExpedition: async (token, id) => {
    return API(`panel/expedition/${id}/price`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetPriceDetailExp: async (token, id, id_price) => {
    return API(`panel/expedition/${id}/price/${id_price}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetPriceExportExp: async (token, id, id_price) => {
    return await API(`panel/expedition/${id}/price/export/${id_price}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePriceExp: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/update/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  AddPriceExp: async (params, id, token) => {
    return API(`panel/expedition/${id}/price/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ImportPriceMaterial: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/import/material/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ImportPriceNonMaterial: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/import/non-material/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeletePriceExp: async (id, id_price, token) => {
    return API(`panel/expedition/${id}/price/delete/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Expedition

  // Shop
  GetShop: async (token, page, query, per_page) => {
    return API(
      `panel/shop?&page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetExportShop: async (token) => {
    return API(`panel/shop/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  GetShopDetail: async (token, id) => {
    return API(`panel/shop/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateShop: async (params, token, id) => {
    return API(`panel/shop/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusShop: async (token, id) => {
    return API(`panel/shop/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteShop: async (token, id) => {
    return API(`panel/shop/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Shop

  // Category
  GetCategory: async (token, query, level, parent_id, page, type, per_page) => {
    return API(
      `panel/category?keyword=${query}&level=${level}&parent_id=${parent_id}&page=${page}&type=${type}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  ExportCategory: async (token) => {
    return API(`panel/category/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  ImportCategory: async (params, token) => {
    return API(`panel/category/import`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  AddCategory: async (params, token) => {
    return API(`panel/category/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateCategory: async (params, token, id) => {
    return API(`panel/category/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusCategory: async (id, token) => {
    return API(`panel/category/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteCategory: async (token, id) => {
    return API(`panel/category/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Category

  // Product
  GetAllProduct: async (token, query, page, per_page) => {
    return API(
      `panel/product?keyword=${query}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetExportProduct: async (token) => {
    return API(`panel/product/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProduct: async (params, id, token) => {
    return API(`panel/product/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductDetail: async (token, id) => {
    return API(`panel/product/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductVariant: async (token, id) => {
    return API(`panel/product/variant/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductVariant: async (params, token, id) => {
    return API(`panel/product/variant/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductSubVariant: async (params, token, id) => {
    return API(`panel/product/variant/sub/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductDetailVariant: async (params, token, id) => {
    return API(`panel/product/variant/detail/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusProduct: async (token, params) => {
    return API(`panel/product/status/update`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteProduct: async (token, params) => {
    return API(`panel/product/delete`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Transaction
  GetTransaction: async (token, page, query, per_page) => {
    return API(
      `panel/transaction?page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetTransactionDetail: async (token, id) => {
    return API(`panel/transaction/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetComplainTransaction: async (token, page, per_page) => {
    return API(`panel/transaction/complain?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusComplain: async (token, id, params) => {
    return API(`panel/transaction/complain/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  SendMessageComplain: async (token, id, params) => {
    return API(`panel/transaction/complain/discussion/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  TrackingTransaction: async (token, id) => {
    return API(`panel/transaction/tracking/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  CheckReferral: async (token, params) => {
    return API(`panel/transaction/referral/check`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ReferalTransaction: async (token, id, params) => {
    return API(`panel/transaction/referral/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Discussion
  GetChatComplainDetail: async (token, id, page, per_page) => {
    return API(
      `/panel/transaction/complain/discussion/${id}?page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  SendChatComplain: async (params, token, id) => {
    return API(`/panel/transaction/complain/discussion/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Discussion

  // Chat
  GetChat: async (token, role) => {
    return API(`panel/conversation?role=${role}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetDetailChat: async (token, id, page, per_page) => {
    return API(`panel/conversation/${id}?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  SendChat: async (params, token, id) => {
    return API(`panel/conversation/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Chat

  // Withdraw
  GetWithdraw: async (token, query, page, per_page) => {
    return API(
      `panel/withdraw?keyword=${query}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusWithdraw: async (params, token, id) => {
    return API(`panel/withdraw/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Withdraw

  // Report
  GetReport: async (token, start_date, end_date, page, query, per_page) => {
    return API(
      `panel/report?start_date=${start_date}&end_date=${end_date}&page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  ExportReport: async (token, start_date, end_date) => {
    return API(
      `panel/report/export?start_date=${start_date}&end_date=${end_date}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      }
    );
  },
  // End Report

  // Notification
  GetNotification: async (token, page, per_page) => {
    return API(`panel/notification?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetNotificationHistory: async (token) => {
    return API(`panel/notification/history`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  SendNotification: async (params, token) => {
    return API(`panel/notification/broadcast`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  GetNotificationList: async (token, page, per_page) => {
    return API(`panel/notification?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetNotificationCount: async (token) => {
    return API(`panel/notification/count`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  ReadNotification: async (token, id) => {
    return API(`panel/notification/read/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  ReadAllNotification: async (token) => {
    return API(`panel/notification/read/all`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteNotification: async (token, id) => {
    return API(`panel/notification/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Notification

  // Banner
  GetBanner: async (token, page, query, per_page) => {
    return API(
      `panel/banner?page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  AddBanner: async (params, token) => {
    return API(`panel/banner/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateBanner: async (params, token, id) => {
    return API(`panel/banner/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteBanner: async (token, id) => {
    return API(`panel/banner/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusBanner: async (token, id) => {
    return API(`panel/banner/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Banner

  // Fee
  GetFee: async (token) => {
    return API(`panel/fee`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateFee: async (params, token, id) => {
    return API(`panel/fee/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Fee

  // Payment
  GetPayment: async (token) => {
    return API(`panel/payment`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePayment: async (params, token, id) => {
    return API(`panel/payment/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Payment

  // Version
  GetVersion: async (token) => {
    return API(`panel/version`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateVersion: async (params, token, id) => {
    return API(`panel/version/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Version

  // Log
  GetLog: async (token, app_type, http_status, page, per_page) => {
    return API(
      `panel/log?app_type=${app_type}&http_status=${http_status}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  // End Log

  // Holiday
  GetHoliday: async (token) => {
    return API(`panel/holiday`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  AddHoliday: async (params, token) => {
    return API(`panel/holiday/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateHoliday: async (params, token, id) => {
    return API(`panel/holiday/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteHoliday: async (token, id) => {
    return API(`panel/holiday/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Holiday

  // Aduit Log
  GetAuditLog: async (
    token,
    user_id,
    keyword,
    action,
    start_date,
    end_date,
    page,
    per_page
  ) => {
    return API(
      `panel/audit-log?user_id=${user_id}&keyword=${keyword}&action=${action}&start_date=${start_date}&end_date=${end_date}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
};

export default apiProvider;
